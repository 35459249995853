exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actiontec-and-edgeq-announces-js": () => import("./../../../src/pages/actiontec-and-edgeq-announces.js" /* webpackChunkName: "component---src-pages-actiontec-and-edgeq-announces-js" */),
  "component---src-pages-better-together-how-artificial-js": () => import("./../../../src/pages/better-together-how-artificial.js" /* webpackChunkName: "component---src-pages-better-together-how-artificial-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-one-js": () => import("./../../../src/pages/blog-one.js" /* webpackChunkName: "component---src-pages-blog-one-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-edgeq-adds-former-fedral-js": () => import("./../../../src/pages/edgeq-adds-former-fedral.js" /* webpackChunkName: "component---src-pages-edgeq-adds-former-fedral-js" */),
  "component---src-pages-edgeq-adds-former-qualcomm-js": () => import("./../../../src/pages/edgeq-adds-former-qualcomm.js" /* webpackChunkName: "component---src-pages-edgeq-adds-former-qualcomm-js" */),
  "component---src-pages-edgeq-and-vodafone-debut-js": () => import("./../../../src/pages/edgeq-and-vodafone-debut.js" /* webpackChunkName: "component---src-pages-edgeq-and-vodafone-debut-js" */),
  "component---src-pages-edgeq-and-vodafone-partner-js": () => import("./../../../src/pages/edgeq-and-vodafone-partner.js" /* webpackChunkName: "component---src-pages-edgeq-and-vodafone-partner-js" */),
  "component---src-pages-edgeq-launches-industrys-first-js": () => import("./../../../src/pages/edgeq-launches-industrys-first.js" /* webpackChunkName: "component---src-pages-edgeq-launches-industrys-first-js" */),
  "component---src-pages-edgeq-partners-with-mavenir-js": () => import("./../../../src/pages/edgeq-partners-with-mavenir.js" /* webpackChunkName: "component---src-pages-edgeq-partners-with-mavenir-js" */),
  "component---src-pages-edgeq-press-kit-1-js": () => import("./../../../src/pages/EdgeqPressKit1.js" /* webpackChunkName: "component---src-pages-edgeq-press-kit-1-js" */),
  "component---src-pages-edgeq-press-kit-2-js": () => import("./../../../src/pages/EdgeqPressKit2.js" /* webpackChunkName: "component---src-pages-edgeq-press-kit-2-js" */),
  "component---src-pages-edgeq-press-kit-3-js": () => import("./../../../src/pages/EdgeqPressKit3.js" /* webpackChunkName: "component---src-pages-edgeq-press-kit-3-js" */),
  "component---src-pages-edgeq-press-kit-4-js": () => import("./../../../src/pages/EdgeqPressKit4.js" /* webpackChunkName: "component---src-pages-edgeq-press-kit-4-js" */),
  "component---src-pages-edgeq-press-kit-js": () => import("./../../../src/pages/EdgeqPressKit.js" /* webpackChunkName: "component---src-pages-edgeq-press-kit-js" */),
  "component---src-pages-edgeq-raises-51-m-in-js": () => import("./../../../src/pages/edgeq-raises-51m-in.js" /* webpackChunkName: "component---src-pages-edgeq-raises-51-m-in-js" */),
  "component---src-pages-edgeq-samples-worlds-first-js": () => import("./../../../src/pages/edgeq-samples-worlds-first.js" /* webpackChunkName: "component---src-pages-edgeq-samples-worlds-first-js" */),
  "component---src-pages-edgeq-series-b-pr-js": () => import("./../../../src/pages/edgeq-series-b-pr.js" /* webpackChunkName: "component---src-pages-edgeq-series-b-pr-js" */),
  "component---src-pages-edgeq-showcases-and-samples-js": () => import("./../../../src/pages/edgeq-showcases-and-samples.js" /* webpackChunkName: "component---src-pages-edgeq-showcases-and-samples-js" */),
  "component---src-pages-edgeq-wins-multiple-js": () => import("./../../../src/pages/edgeq-wins-multiple.js" /* webpackChunkName: "component---src-pages-edgeq-wins-multiple-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-making-5-g-spectrum-js": () => import("./../../../src/pages/making-5G-spectrum.js" /* webpackChunkName: "component---src-pages-making-5-g-spectrum-js" */),
  "component---src-pages-max-linear-partners-with-edgeq-js": () => import("./../../../src/pages/maxLinear-partners-with-edgeq.js" /* webpackChunkName: "component---src-pages-max-linear-partners-with-edgeq-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-press-details-js": () => import("./../../../src/pages/pressDetails.js" /* webpackChunkName: "component---src-pages-press-details-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-usecase-js": () => import("./../../../src/pages/usecase.js" /* webpackChunkName: "component---src-pages-usecase-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-wnc-and-edgeq-to-introduce-js": () => import("./../../../src/pages/wnc-and-edgeq-to-introduce.js" /* webpackChunkName: "component---src-pages-wnc-and-edgeq-to-introduce-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

